import { createContext, ReactNode, useContext, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type ToastContextProps = {
	handleToast: (title: string, text: string, timeout?: number, variant?: string) => void;
    clearToast: () => void;
};

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export function useToast(){
	return useContext(ToastContext);
}

type ToastProviderProps = {
    children: ReactNode;
}

type ToastProps = {
    title: string;
    text: string;
    timeout?: number;
    variant?: string;
    show: boolean;
}

export default function ToastProvider({children}: ToastProviderProps){

    const [toasts, setToasts] = useState<ToastProps[]>([]);

    function handleToast(title: string, text: string, timeout?: number, variant?: string){
        setToasts(prev => [...prev, {title, text, timeout, variant, show: true}]);
    }

    function handleClose(index: number){
        setToasts(prev => prev.map((item, prevIndex)=> prevIndex===index ? {...item, show: false} : item ));
    }

    function clearToast(){
        setToasts([]);
    }

    const value = {
        handleToast,
        clearToast
	}

    return (
        <ToastContext.Provider value={value}>
            <ToastContainer containerPosition="fixed" position="top-end" className="p-3">
                <>
                    {toasts.map((item, index)=>{
                        return (
                            <Toast bg={item.variant} key={index} onClose={() => handleClose(index)} show={item.show} delay={item.timeout} autohide={item.timeout ? true : false}>
                                <Toast.Header>
                                    <strong className="me-auto fw-light">{item.title}</strong>
                                </Toast.Header>
                                <Toast.Body>{item.text}</Toast.Body>
                            </Toast>
                        )
                    })}
                </>
            </ToastContainer>
            {children}
        </ToastContext.Provider>
    )
}