import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { useEffect } from 'react';

export default function RouteAdmin({ children }: { children: JSX.Element }) {

	const { user } = useAuth();
	const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(()=>{
        if(user && user.userType!==2){
            navigate(-1);
        }
    // eslint-disable-next-line
    },[user])

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return user.userType===2 ? children : <></>;
}