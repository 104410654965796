import { mdiAccountCogOutline, mdiAccountMultiple, mdiAdvertisements, mdiAlert, mdiCalendar, mdiHomeGroup, mdiViewDashboard } from "@mdi/js";

export const screens = [
    {
        name: 'Dashboard',
        description: 'Lista de indicadores da plataforma',
        icon: mdiViewDashboard,
        route: '/dashboard',
        showSidebar: true,
        showDashboard: true,
    },
    {
        name: 'Denúncias',
        description: 'Lista de denúncias para gerenciar',
        icon: mdiAlert,
        route: '/denuncias',
        showSidebar: true,
        showDashboard: true,
    },
    {
        name: 'Grupos',
        description: 'Lista de Grupos para gerenciar permissões',
        icon: mdiHomeGroup,
        route: '/gruposPendentes',
        showSidebar: false,
        showDashboard: true,
    },
    {
        name: 'Eventos',
        description: 'Lista de eventos para gerenciar',
        icon: mdiCalendar,
        route: '/eventosLista',
        showSidebar: true,
        showDashboard: true,
    },
    {
        name: 'Anúncios',
        description: 'Lista de anúncios para gerenciar',
        icon: mdiAdvertisements,
        route: '/anuncios',
        showSidebar: true,
        showDashboard: true,
    },
    {
        name: 'Usuários',
        description: 'Lista de usuários',
        icon: mdiAccountMultiple,
        route: '/usuarios',
        showSidebar: false,
        showDashboard: true,
    },
    {
        name: 'Administradores',
        description: 'Lista de administradores para gerenciar permissões',
        icon: mdiAccountCogOutline,
        route: '/administradores',
        showSidebar: false,
        showDashboard: true,
    },
    // {
    //     name: 'Grupos de Usuários',
    //     description: 'Lista de Grupos de usuários para gerenciar permissões',
    //     icon: mdiAccountGroup,
    //     route: '/grupos',
    //     showSidebar: false,
    //     showDashboard: true,
    // },
    // {
    //     name: 'Configurações',
    //     description: 'Lista de configurações para gerenciamento',
    //     icon: mdiCog,
    //     route: '/configuracoesEmpresas',
    //     showSidebar: true,
    //     showDashboard: true,
    // },
];