import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { screens } from "../config/screens";
import { PersonGetPersonResponseEntity } from "../entities/PersonEntity";
import { LoginPostResponseEntity } from "../entities/LoginEntity";
import CommunityService from "../services/CommunityService";
import AppLoader from "../components/AppLoader";

export type UserProps = {
	person: PersonGetPersonResponseEntity['Data'];
} & LoginPostResponseEntity['Data'];

type AuthContextProps = {
	feed: boolean;
	user: UserProps | undefined;
	routeActive: string;
	handleLogin: (data: UserProps) => void; 
	handleLogout: () => void;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth(){
	return useContext(AuthContext);
}

type AuthProviderProps = {
    children: ReactNode;
}

const communityService = new CommunityService();

export default function AuthProvider({children}: AuthProviderProps){

    const navigate = useNavigate();
    const location = useLocation();
	
	const [loading, setLoading] = useState(true);
	const [feed, setFeed] = useState(false);
	const [user, setUser] = useState<UserProps | undefined>();
	const [routeActive, setRouteActive] = useState<string>("");

	useEffect(()=>{
		let routeActive : string | false = false;
		screens.forEach((screen)=>{
			if(screen.route===location.pathname && screen.showSidebar){
				routeActive = location.pathname;
			}
		});
		if(routeActive!==false){
			setRouteActive(routeActive);
		}
	},[location]);

	useEffect(()=>{
		handleInitial();
	// eslint-disable-next-line
	},[]);

	async function handleInitial(){
		let sessionUser = sessionStorage.getItem("user");
		if(sessionUser){
			let userJson = JSON.parse(sessionUser);
			setUser(userJson);
		}

		let resp = await communityService.getCommunities({},{"X-PageSize": 1});
		if(resp.Data && resp.Data?.length>=1){
			setFeed(true);
		}else if(location.pathname==="/feed" || location.pathname==="/"){
			navigate("/login");
		}

		setLoading(false);
	}

	async function handleLogin(data: UserProps){
		sessionStorage.setItem("user", JSON.stringify(data));
		setUser(data);
	}

	function handleLogout(){
		setUser(undefined);
		sessionStorage.removeItem("user");
		navigate("/login");
		return;
	}

    const value = {
		feed,
		user,
		routeActive,
		handleLogin,
		handleLogout,
	}

	if(loading){
		return <AppLoader/>;
	}

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
}