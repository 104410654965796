import { initializeApp } from 'firebase/app';
import { isSupported, Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { queryClient } from './QueryClientService';
import AlertService from './AlertService';
import { EntityType } from '../config/defines';

const alertService = new AlertService();

const app = initializeApp({
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
});

var registration : ServiceWorkerRegistration | null = null;
var messaging : Messaging | null = null;

async function inicializarFirebase(){

    let supported = await isSupported();
    if(supported){
        messaging = getMessaging(app);

        onMessage(messaging, (payload)=>{
            queryClient.refetchQueries('alerts');

            if(payload.data){
                const notificationTitle = payload.data.Title;
                const notificationOptions = {
                    body: payload.data.Message,
                    tag: payload.data.Tag,
                    icon: process.env.REACT_APP_BASE_URL + process.env.PUBLIC_URL + '/icone_52.png',
                    badge: process.env.REACT_APP_BASE_URL + process.env.PUBLIC_URL + '/icone_52.png',
                    image: process.env.REACT_APP_BASE_URL + process.env.PUBLIC_URL + '/icone_52.png',
                };

                try{
                    let notification = new Notification(notificationTitle, notificationOptions);
                    notification.onclick = function(event: any) {
                        if(event.target.tag){
                            let url = handleUrlTag(process.env.PUBLIC_URL, event.target.tag);
                            window.location.href = url;
                        }
                        window.focus();
                        notification.close();
                    };
                }catch(e){
                    console.log(e);
                }
            }
        })
    }

    if ("serviceWorker" in navigator) {
        
        registration = await navigator.serviceWorker.register(process.env.PUBLIC_URL + '/firebase-messaging-sw.js');

        navigator.serviceWorker.addEventListener('message', (event) => {
            if (!event.data.action) {
                return
            }          
            if(event.data.action==="redirect-from-notificationclick") {
                if(event.data.tag){
                    let url = handleUrlTag(process.env.PUBLIC_URL, event.data.tag);
                    window.location.href = url;
                }
            }

        })
    }
}

async function iniciarServiceWorker(){
    await requestPermission();
}

async function handleGetToken(){
    if(messaging && registration){
        return await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY, serviceWorkerRegistration: registration });
    }
} 

async function handleToken(){
    if(messaging){
        let currentToken = await handleGetToken();
        if (currentToken) {
            await alertService.postRegistrarDevice({deviceToken: "", appToken: currentToken});
        }
    }
}

async function requestPermission() {
    let permission = await Notification.requestPermission();
    if (permission === 'granted') {
        await handleToken();
    }
}

function handleUrlTag(url: string, tag: string){
    let tagJSON = JSON.parse(tag);
    let newURL = url;
    if(tagJSON.typeId===EntityType.Person){
        newURL += "/perfil/"+tagJSON.entityId;
    }
    if(tagJSON.typeId===EntityType.Community){
        newURL += "/grupo/"+tagJSON.entityId;
    }
    if(tagJSON.typeId===EntityType.Post){
        newURL += "/postDetalhes/"+tagJSON.entityId;
    }
    return newURL;
}

export {
    inicializarFirebase,
    iniciarServiceWorker,
    handleGetToken
}