import axios from "axios";
import { ApiResponseEntity } from "../entities/ApiResponseEntity";
import { AlertaAutocompleteResponseEntity, AlertaEntity, AlertaGetListResponseEntity, AlertaGetNavegacaoResponseEntity, AlertaGetResponseEntity, AlertaPostResponseEntity } from "../entities/AlertEntity";
import { UsuarioEntity, UsuarioGetAplicativosResponseEntity, UsuarioGetEmpresasResponseEntity, UsuarioGetGruposResponseEntity, UsuarioGetListResponseEntity, UsuarioGetResponseEntity, UsuarioPostResponseEntity } from "../entities/UsuarioEntity";
import { GrupoGetListResponseEntity, GrupoGetPermissoesResponseEntity, GrupoGetResponseEntity, GrupoPostEntity, GrupoPostResponseEntity } from "../entities/GrupoEntity";
import { LoginEntity } from "../entities/LoginEntity";
import { ConfiguracaoEntity, ConfiguracaoGetAplicativosResponseEntity, ConfiguracaoGetEmpresasResponseEntity, ConfiguracaoGetResponseEntity, ConfiguracaoPostResponseEntity } from "../entities/ConfiguracaoEntity";
import moment from "moment-timezone";

const Request = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL})

/* ALERTA */
export async function alertaGetList(pagina: number, filtro?: {filtro?: string, periodoInicial?: string, periodoFinal?: string}): Promise<AlertaGetListResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let parametros = filtro ? Object.keys(filtro).map((key)=>{ return filtro[key as keyof typeof filtro] ? `&${key}=${filtro[key as keyof typeof filtro]}` : '' }).join('') : '';
    let response;
    try{
        let req = await Request.get<AlertaGetListResponseEntity>(`/alerta/gerenciador/v1/Alerta/GetList?pagina=${pagina}${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaGetNavegacao(aplicativo: number): Promise<AlertaGetNavegacaoResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<AlertaGetNavegacaoResponseEntity>(`/alerta/gerenciador/v1/Alerta/GetServicos?aplicativoId=${aplicativo}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaGet(id: string): Promise<AlertaGetResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<AlertaGetResponseEntity>(`/alerta/gerenciador/v1/Alerta/getAlerta?alertaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaPost(data: AlertaEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<AlertaPostResponseEntity>("/alerta/gerenciador/v1/Alerta/PostAlerta", data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaDelete(id: number){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.delete<ApiResponseEntity>(`/alerta/gerenciador/v1/Alerta/Delete?alertaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaAutocomplete(search: string): Promise<AlertaAutocompleteResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<AlertaAutocompleteResponseEntity>(`alerta/gerenciador/v1/Alerta/Autocomplete?param=${search}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

/* GRUPO */
export async function grupoGetList(pagina: number, filtro?: {filtro?: string}): Promise<GrupoGetListResponseEntity> {
    return {
        "Result": 1,
        "Message": "",
        "Data": {
            "grupos": [
                {
                    "grupoId": 1,
                    "imagem": "/oncologia.png",
                    "nome": "ONCOLOGIA",
                    "descricao": "Notícias sobre oncologia",
                },
                {
                    "grupoId": 2,
                    "nome": "CARDIOLOGIA",
                    "imagem": "/cardiologia.png",
                    "descricao": "Notícias sobre cardiologia",
                },
                {
                    "grupoId": 3,
                    "nome": "NEUROLOGIA",
                    "imagem": "/neurologia.png",
                    "descricao": "Notícias sobre neurologia",
                },
                {
                    "grupoId": 4,
                    "nome": "OUTRAS",
                    "imagem": "/outras.png",
                    "descricao": "Notícias sobre outras",
                }
            ],
            nextPage: undefined
        }
    };
    // let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    // let parametros = filtro ? Object.keys(filtro).map((key)=>{ return filtro[key as keyof typeof filtro] ? `&${key}=${filtro[key as keyof typeof filtro]}` : '' }).join('') : '';
    // let response;
    // try{
    //     let req = await Request.get<GrupoGetListResponseEntity>(`/gerenciador/api/v1/Grupo/GetList?pagina=${pagina}${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
    //     response = req.data;
    // }catch(e){
    //     return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    // }
    // return response;
}

export async function grupoGetPermissoes(): Promise<GrupoGetPermissoesResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<GrupoGetPermissoesResponseEntity>(`/gerenciador/api/v1/Grupo/GetPermissoes`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

let grupoGetMOCK = [
    {
        "grupoId": 1,
        "background": "/group_background.jpeg",
        "imagem": "/oncologia.png",
        "nome": "ONCOLOGIA",
        "descricao": "Notícias sobre oncologia",
        "boasVindasTitulo": "Sobre oncologia",
        "boasVindasDescricao": "A oncologia é a disciplina que estuda como o câncer se desenvolve no organismo e indica qual o tratamento mais adequado para cada caso. A Oncologia é a especialidade médica que estuda as neoplasias, ou tumores, benignas ou malignas.",
    },
    {
        "grupoId": 2,
        "background": "/group_background.jpeg",
        "imagem": "/cardiologia.png",
        "nome": "CARDIOLOGIA",
        "descricao": "Notícias sobre cardiologia",
        "boasVindasTitulo": "Sobre cardiologia",
        "boasVindasDescricao": "Cardiologia é a especialidade médica que se ocupa do diagnóstico e tratamento das doenças que acometem o coração bem como os outros componentes do sistema circulatório.",
    },
    {
        "grupoId": 3,
        "background": "/group_background.jpeg",
        "imagem": "/neurologia.png",
        "nome": "NEUROLOGIA",
        "descricao": "Notícias sobre neurologia",
        "boasVindasTitulo": "Sobre neurologia",
        "boasVindasDescricao": "Neurologia é a especialidade da medicina que trata das doenças relacionadas com o “sistema nervoso”. Este é composto pelo encéfalo (cérebro e suas outras estruturas), medula e nervos periféricos.",
    },
    {
        "grupoId": 4,
        "background": "/group_background.jpeg",
        "imagem": "/outras.png",
        "nome": "OUTRAS",
        "descricao": "Notícias sobre outras",
        "boasVindasTitulo": "Boas-vindas",
        "boasVindasDescricao": "Parabéns por se tornar um membro do grupo",
    }
];
export async function grupoGet(id: string): Promise<GrupoGetResponseEntity> {
    return {
        "Result": 1,
        "Message": "",
        "Data": grupoGetMOCK[Number(id)-1]
    };
    // let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    // let response;
    // try{
    //     let req = await Request.get<GrupoGetResponseEntity>(`/gerenciador/api/v1/Grupo/GetGrupo?grupoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
    //     response = req.data;
    // }catch(e){
    //     return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    // }
    // return response;
}

export async function grupoPost(data: GrupoPostEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<GrupoPostResponseEntity>(`/gerenciador/api/v1/Grupo/PostGrupo${data.empresaId ? `?empresaId=${data.empresaId}` : ""}`,data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function grupoDelete(id: number){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.delete<ApiResponseEntity>(`/gerenciador/api/v1/Grupo/Delete?grupoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

/* USUARIO */
export async function usuarioGetList(pagina: number, filtro?: {filtro?: string}): Promise<UsuarioGetListResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let parametros = filtro ? Object.keys(filtro).map((key)=>{ return filtro[key as keyof typeof filtro] ? `&${key}=${filtro[key as keyof typeof filtro]}` : '' }).join('') : '';
    let response;
    try{
        let req = await Request.get<UsuarioGetListResponseEntity>(`/gerenciador/api/v1/Usuarios/GetList?pagina=${pagina}${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGetEmpresas(): Promise<UsuarioGetEmpresasResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetEmpresasResponseEntity>(`/gerenciador/api/v1/Usuarios/GetEmpresas`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGetGrupos(empresa?: number): Promise<UsuarioGetGruposResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetGruposResponseEntity>(`/gerenciador/api/v1/Usuarios/GetGrupos${empresa ? `?empresaId=${empresa}` : ""}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGetAplicativos(empresa?: number): Promise<UsuarioGetAplicativosResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetAplicativosResponseEntity>(`/gerenciador/api/v1/Usuarios/GetAplicativos${empresa ? `?empresaId=${empresa}` : ""}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGet(id: string): Promise<UsuarioGetResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetResponseEntity>(`/gerenciador/api/v1/Usuarios/GetUsuario?usuarioId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioPost(data: UsuarioEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<UsuarioPostResponseEntity>("/gerenciador/api/v1/Usuarios/PostUsuario", data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioDelete(id: number){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.delete<ApiResponseEntity>(`/gerenciador/api/v1/Usuarios/Delete?usuarioId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

/* CONFIGURACOES */
export async function configuracaoGetEmpresas(): Promise<ConfiguracaoGetEmpresasResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<ConfiguracaoGetEmpresasResponseEntity>(`/gerenciador/api/v1/Configuracao/GetEmpresas`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function configuracaoGetAplicativos(empresa: number): Promise<ConfiguracaoGetAplicativosResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<ConfiguracaoGetAplicativosResponseEntity>(`/gerenciador/api/v1/Configuracao/GetAplicativos?empresaId=${empresa}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function configuracaoGet(empresaId: string, aplicativoId: string): Promise<ConfiguracaoGetResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<ConfiguracaoGetResponseEntity>(`/gerenciador/api/v1/Configuracao/GetConfiguracao?empresaId=${empresaId}&aplicativoId=${aplicativoId}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function configuracaoPost(data: ConfiguracaoEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<ConfiguracaoPostResponseEntity>("/gerenciador/api/v1/Configuracao/PostConfiguracao",data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}







/* API */
export default class ApiService{

    protected request = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL});

    protected mock = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL_MOCK});
    
    constructor(){
        let user = this.user();
        this.request.defaults.headers.common['Authorization'] = `Bearer ${user.hashLogin}`;
        this.request.defaults.headers.common['X-FusoHorarioId'] = moment.tz.guess();
    }

    protected user(){
        return JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    }

    public baseUrl(baseUrl: string){
        this.request.defaults.baseURL = baseUrl;
        this.mock.defaults.baseURL = baseUrl;
    }

}