import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';

import { queryClient } from './services/QueryClientService';
import { inicializarFirebase } from './services/FirebaseService';

import App from './App';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');

const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
);

root.render(
    <QueryClientProvider client={queryClient}>
      	<App />
    </QueryClientProvider>
);

inicializarFirebase();