import ApiService from "./ApiService";
import { ApiResponseEntity } from "../entities/ApiResponseEntity";
import {
    CommunityGetCommunitiesRequestEntity,
    CommunityGetCommunitiesResponseEntity,
    CommunityGetCommunityRequestEntity,
    CommunityGetCommunityResponseEntity,
    CommunityPostCommunityImageRequestEntity,
    CommunityPostCommunityImageResponseEntity,
    CommunityPostCommunityCreationRequestEntity,
    CommunityPostCommunityCreationResponseEntity,
    CommunityUpdateCommunityRequestEntity,
    CommunityUpdateCommunityResponseEntity,
    CommunityGetPendingCommunitiesRequestEntity,
    CommunityGetPendingCommunitiesResponseEntity,
    CommunityPostCommunityCreationAnswerRequestEntity,
    CommunityPostCommunityCreationAnswerResponseEntity,
    CommunityGetSuggestedCommunitiesRequestEntity,
    CommunityGetSuggestedCommunitiesResponseEntity,
    CommunityGetSuggestedMembersRequestEntity,
    CommunityGetSuggestedMembersResponseEntity,
    CommunityGetCommunityCategoriesRequestEntity,
    CommunityGetCommunityCategoriesResponseEntity,
    CommunityGetDefaultCommunitiesRequestEntity,
    CommunityGetDefaultCommunitiesResponseEntity,
    CommunityPostDefaultCommunityRequestEntity,
    CommunityPostDefaultCommunityResponseEntity
} from "../entities/CommunityEntity";

export default class CommunityService extends ApiService {

    public async getCommunity(data: CommunityGetCommunityRequestEntity): Promise<CommunityGetCommunityResponseEntity> {
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<CommunityGetCommunityResponseEntity>(`/api/v1/GetCommunity?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getCommunities(data: CommunityGetCommunitiesRequestEntity, headers: any = {}): Promise<CommunityGetCommunitiesResponseEntity> {
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<CommunityGetCommunitiesResponseEntity>(`/api/v1/GetCommunities?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`, ...headers}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getDefaultCommunities(data: CommunityGetDefaultCommunitiesRequestEntity, headers: any = {}): Promise<CommunityGetDefaultCommunitiesResponseEntity> {
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<CommunityGetDefaultCommunitiesResponseEntity>(`/api/v1/GetDefaultCommunities?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`, ...headers}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getSuggestedCommunities(data: CommunityGetSuggestedCommunitiesRequestEntity, headers: any = {}): Promise<CommunityGetSuggestedCommunitiesResponseEntity> {
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<CommunityGetSuggestedCommunitiesResponseEntity>(`/api/v1/GetSuggestedCommunities?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`, ...headers}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getSuggestedMembers(data: CommunityGetSuggestedMembersRequestEntity, headers: any = {}): Promise<CommunityGetSuggestedMembersResponseEntity> {
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<CommunityGetSuggestedMembersResponseEntity>(`/api/v1/GetSuggestedMembers?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`, ...headers}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getPendingCommunities(data: CommunityGetPendingCommunitiesRequestEntity, headers: any = {}): Promise<CommunityGetPendingCommunitiesResponseEntity> {
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<CommunityGetPendingCommunitiesResponseEntity>(`/api/v1/GetPendingCommunities?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`, ...headers}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getCommunityCategories(data: CommunityGetCommunityCategoriesRequestEntity): Promise<CommunityGetCommunityCategoriesResponseEntity> {        
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<CommunityGetCommunityCategoriesResponseEntity>(`/api/v1/GetCommunityCategories?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postCommunityCreationAnswer(data: CommunityPostCommunityCreationAnswerRequestEntity): Promise<CommunityPostCommunityCreationAnswerResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CommunityPostCommunityCreationAnswerResponseEntity>("/api/v1/PostCommunityCreationAnswer", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postCommunityCreation(data: CommunityPostCommunityCreationRequestEntity): Promise<CommunityPostCommunityCreationResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CommunityPostCommunityCreationResponseEntity>("/api/v1/PostCommunityCreation", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async updateCommunity(data: CommunityUpdateCommunityRequestEntity): Promise<CommunityUpdateCommunityResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CommunityUpdateCommunityResponseEntity>("/api/v1/UpdateCommunity", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postCommunityImage(data: CommunityPostCommunityImageRequestEntity): Promise<CommunityPostCommunityImageResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CommunityPostCommunityImageResponseEntity>("/api/v1/PostCommunityImage", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postCommunityBanner(data: CommunityPostCommunityImageRequestEntity): Promise<CommunityPostCommunityImageResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CommunityPostCommunityImageResponseEntity>("/api/v1/PostCommunityBanner", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postDefaultCommunity(data: CommunityPostDefaultCommunityRequestEntity): Promise<CommunityPostDefaultCommunityResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CommunityPostDefaultCommunityResponseEntity>("/api/v1/PostDefaultCommunity", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteCommunityImage(communityId: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/DeleteCommunityImage?communityId=${communityId}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteDefaultCommunity(communityId: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/DeleteDefaultCommunity?communityId=${communityId}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteCommunity(communityId: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/DeleteCommunity?communityId=${communityId}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

}