import { Spinner } from "react-bootstrap";

export default function AppLoader(){
	return (
		<div style={{backgroundColor: "rgb(248, 249, 250)", position: 'absolute', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 2000, color: 'var(--cor_principal)', fontWeight: 'bold'}}>
			<img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo"/>
            <br/><br/>
			<Spinner/>
		</div>
	)
}