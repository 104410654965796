import ApiService from "./ApiService";
import {
    AlertGetAlertsRequestEntity,
    AlertGetAlertsResponseEntity,
    AlertPostRegistrarDeviceRequestEntity,
    AlertPostRegistrarDeviceResponseEntity,
    AlertSetAlertReadRequestEntity,
    AlertSetAlertReadResponseEntity,
    AlertSetAllAlertReadRequestEntity,
    AlertSetAllAlertReadResponseEntity
} from "../entities/AlertEntity";

export default class AlertService extends ApiService {

    public async getAlerts(data: AlertGetAlertsRequestEntity, headers: any = {}): Promise<AlertGetAlertsResponseEntity> {
        let user = this.user();
        let parametros = new URLSearchParams(data as any).toString();
        let response;
        try{
            let req = await this.request.get<AlertGetAlertsResponseEntity>(`/api/v1/GetAlerts?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`, ...headers}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async setAlertRead(data: AlertSetAlertReadRequestEntity): Promise<AlertSetAlertReadResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AlertSetAlertReadResponseEntity>("/api/v1/SetAlertRead", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async setAllAlertsRead(data: AlertSetAllAlertReadRequestEntity): Promise<AlertSetAllAlertReadResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AlertSetAllAlertReadResponseEntity>("/api/v1/SetAllAlertsRead", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postRegistrarDevice(data: AlertPostRegistrarDeviceRequestEntity): Promise<AlertPostRegistrarDeviceResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AlertPostRegistrarDeviceResponseEntity>("/api/v1/PostRegistrarDevice", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }
}