import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import TagManager from "react-gtm-module";

import "./App.scss";

import AuthProvider from "./context/AuthContext";
import ToastProvider from "./context/ToastContext";
import SuspenseRouter from "./components/SuspenseRouter";
import RouteAuth from "./components/RouteAuth";
import RouteAdmin from "./components/RouteAdmin";

const Login = lazy(() => import("./screens/Login"));
const Dashboard = lazy(() => import("./screens/Dashboard"));
const Administracao = lazy(() => import("./screens/Administracao"));
const MinhaRede = lazy(() => import("./screens/MinhaRede"));
const Grupos = lazy(() => import("./screens/Grupos"));
const GruposPendentes = lazy(() => import("./screens/GruposPendentes"));
const Grupo = lazy(() => import("./screens/Grupo"));
const GrupoFormulario = lazy(() => import("./screens/GrupoFormulario"));
const GrupoMembros = lazy(() => import("./screens/GrupoMembros"));
const GrupoDashboard = lazy(() => import("./screens/GrupoDashboard"));
const GrupoVagas = lazy(() => import("./screens/GrupoVagas"));
const GrupoTopicos = lazy(() => import("./screens/GrupoTopicos"));
const GrupoTopico = lazy(() => import("./screens/GrupoTopico"));
const Administradores = lazy(() => import("./screens/Administradores"));
const Usuarios = lazy(() => import("./screens/Usuarios"));
const PessoaFormulario = lazy(() => import("./screens/PessoaFormulario"));
const Feed = lazy(() => import("./screens/Feed"));
const Hashtag = lazy(() => import("./screens/Hashtag"));
const Perfil = lazy(() => import("./screens/Perfil"));
const PostDetalhes = lazy(() => import("./screens/PostDetalhes"));
const Denuncias = lazy(() => import("./screens/Denuncias"));
const DenunciaDetalhes = lazy(() => import("./screens/DenunciaDetalhes"));
const Anuncios = lazy(() => import("./screens/Anuncios"));
const AnuncioFormulario = lazy(() => import("./screens/AnuncioFormulario"));
const Lives = lazy(() => import("./screens/Lives"));
const LivesLista = lazy(() => import("./screens/LivesLista"));
const LiveDetalhes = lazy(() => import("./screens/LiveDetalhes"));
const LiveFormulario = lazy(() => import("./screens/LiveFormulario"));
const Eventos = lazy(() => import("./screens/Eventos"));
const EventosLista = lazy(() => import("./screens/EventosLista"));
const EventoDetalhes = lazy(() => import("./screens/EventoDetalhes"));
const EventoFormulario = lazy(() => import("./screens/EventoFormulario"));
const Carreira = lazy(() => import("./screens/Carreira"));
const CarreiraDetalhes = lazy(() => import("./screens/CarreiraDetalhes"));
const CarreiraFormulario = lazy(() => import("./screens/CarreiraFormulario"));
const Pesquisa = lazy(() => import("./screens/Pesquisa"));
const Termos = lazy(() => import("./screens/Termos"));
const MobileEventos = lazy(() => import("./screens/MobileEventos"));
const MobileEventoDetalhes = lazy(() => import("./screens/MobileEventoDetalhes"));
const MobileCarreira = lazy(() => import("./screens/MobileCarreira"));
const MobileCarreiraDetalhes = lazy(() => import("./screens/MobileCarreiraDetalhes"));
const Page404 = lazy(() => import("./screens/Page404"));

declare const window: Window & { dataLayer: Record<string, unknown>[] };
TagManager.initialize({ gtmId: `${process.env.REACT_APP_GTM_ID}` });

function App() {
	window.dataLayer.push({
		event: "pageview",
	});

	return (
		<SuspenseRouter window={window} basename={process.env.PUBLIC_URL}>
			<AuthProvider>
				<ToastProvider>
					<Routes>
						<Route path="/" element={<Feed />} />
						<Route path="/login" element={<Login />} />
						<Route path="/cadastro" element={<Login stage={2} />}>
							<Route index element={<Login stage={2} />} />
							<Route path=":token" element={<Login stage={2} />} />
						</Route>
						<Route
							path="/dashboard"
							element={
								<RouteAdmin>
									<Dashboard />
								</RouteAdmin>
							}
						/>
						<Route
							path="/administracao"
							element={
								<RouteAdmin>
									<Administracao />
								</RouteAdmin>
							}
						/>
						<Route
							path="/feed"
							element={
								<RouteAuth>
									<Feed />
								</RouteAuth>
							}
						/>
						<Route
							path="/hashtag/:id/:hashtag"
							element={
								<RouteAuth>
									<Hashtag />
								</RouteAuth>
							}
						/>
						<Route
							path="/pesquisa/:search"
							element={
								<RouteAuth>
									<Pesquisa />
								</RouteAuth>
							}
						/>
						<Route path="/postDetalhes/:id" element={<PostDetalhes />} />
						<Route
							path="/perfil/:id"
							element={
								<RouteAuth>
									<Perfil />
								</RouteAuth>
							}
						/>
						<Route
							path="/minhaRede"
							element={
								<RouteAuth>
									<MinhaRede />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<MinhaRede />
									</RouteAuth>
								}
							/>
							<Route
								path=":tab"
								element={
									<RouteAuth>
										<MinhaRede />
									</RouteAuth>
								}
							/>
							<Route
								path=":tab/:id"
								element={
									<RouteAuth>
										<MinhaRede />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/gruposPendentes"
							element={
								<RouteAdmin>
									<GruposPendentes />
								</RouteAdmin>
							}
						></Route>
						<Route path="/grupos" element={<Grupos />}>
							<Route index element={<Grupos />} />
							<Route
								path=":id"
								element={
									<RouteAuth>
										<Grupos />
									</RouteAuth>
								}
							/>
						</Route>
						<Route path="/grupo/:id" element={<Grupo />} />
						<Route path="/grupoDashboard/:id" element={<GrupoDashboard />} />
						<Route path="/grupoMembros/:id" element={<GrupoMembros />} />
						<Route path="/grupoVagas/:id" element={<GrupoVagas />} />
						<Route path="/grupoTopicos/:id" element={<GrupoTopicos />} />
						<Route path="/grupoTopico/:id/:topicId" element={<GrupoTopico />} />
						<Route
							path="/grupoFormulario"
							element={
								<RouteAuth>
									<GrupoFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<GrupoFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<GrupoFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/denuncias"
							element={
								<RouteAdmin>
									<Denuncias />
								</RouteAdmin>
							}
						/>
						<Route
							path="/denunciaDetalhes/:id"
							element={
								<RouteAdmin>
									<DenunciaDetalhes />
								</RouteAdmin>
							}
						/>
						<Route
							path="/anuncios"
							element={
								<RouteAdmin>
									<Anuncios />
								</RouteAdmin>
							}
						/>
						<Route
							path="/anuncioFormulario"
							element={
								<RouteAdmin>
									<AnuncioFormulario />
								</RouteAdmin>
							}
						>
							<Route
								index
								element={
									<RouteAdmin>
										<AnuncioFormulario />
									</RouteAdmin>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAdmin>
										<AnuncioFormulario />
									</RouteAdmin>
								}
							/>
						</Route>
						<Route
							path="/lives"
							element={
								<RouteAuth>
									<Lives />
								</RouteAuth>
							}
						/>
						<Route
							path="/livesLista"
							element={
								<RouteAdmin>
									<LivesLista />
								</RouteAdmin>
							}
						/>
						<Route
							path="/liveDetalhes/:id"
							element={
								<RouteAuth>
									<LiveDetalhes />
								</RouteAuth>
							}
						/>
						<Route
							path="/liveFormulario"
							element={
								<RouteAdmin>
									<LiveFormulario />
								</RouteAdmin>
							}
						>
							<Route
								index
								element={
									<RouteAdmin>
										<LiveFormulario />
									</RouteAdmin>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAdmin>
										<LiveFormulario />
									</RouteAdmin>
								}
							/>
						</Route>
						<Route
							path="/eventos"
							element={
								<RouteAuth>
									<Eventos />
								</RouteAuth>
							}
						/>
						<Route
							path="/eventosLista"
							element={
								<RouteAdmin>
									<EventosLista />
								</RouteAdmin>
							}
						/>
						<Route
							path="/eventoDetalhes/:id"
							element={
								<RouteAuth>
									<EventoDetalhes />
								</RouteAuth>
							}
						/>
						<Route
							path="/eventoFormulario"
							element={
								<RouteAdmin>
									<EventoFormulario />
								</RouteAdmin>
							}
						>
							<Route
								index
								element={
									<RouteAdmin>
										<EventoFormulario />
									</RouteAdmin>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAdmin>
										<EventoFormulario />
									</RouteAdmin>
								}
							/>
						</Route>
						<Route
							path="/carreira"
							element={
								<RouteAuth>
									<Carreira />
								</RouteAuth>
							}
						/>
						<Route
							path="/carreiraDetalhes/:id"
							element={
								<RouteAuth>
									<CarreiraDetalhes />
								</RouteAuth>
							}
						/>
						<Route
							path="/carreiraFormulario/:id"
							element={
								<RouteAuth>
									<CarreiraFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<CarreiraFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":jobId"
								element={
									<RouteAuth>
										<CarreiraFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/administradores"
							element={
								<RouteAdmin>
									<Administradores />
								</RouteAdmin>
							}
						/>
						<Route
							path="/usuarios"
							element={
								<RouteAdmin>
									<Usuarios />
								</RouteAdmin>
							}
						/>
						<Route
							path="/pessoaFormulario"
							element={
								<RouteAuth>
									<PessoaFormulario />
								</RouteAuth>
							}
						/>
						<Route path="/termos-de-uso" element={<Termos />} />
						<Route path="/politica-de-privacidade" element={<Termos />} />

						<Route path="/mobile/eventos" element={<MobileEventos />} />
						<Route path="/mobile/eventoDetalhes/:id" element={<MobileEventoDetalhes />} />
						<Route path="/mobile/carreira" element={<MobileCarreira />} />
						<Route path="/mobile/carreiraDetalhes/:id" element={<MobileCarreiraDetalhes />} />
						<Route path="*" element={<Page404 />} />
					</Routes>
				</ToastProvider>
			</AuthProvider>
		</SuspenseRouter>
	);
}

export default App;
