type Unpacked<T> = T extends (infer U)[] ? U : T;

const AsyncSelectStyle = {
    control: (styles: any, state: any) => {
        return {
            ...styles,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : 'none',
            borderRadius: '20px',
            backgroundColor: state.isDisabled ? "#e9ecef" : "white",
        }
    },
    multiValue: (styles: any, state: any) => {
        return {
            ...styles,
            borderRadius: '20px'
        }
    },
    singleValue: (styles: any, state: any) => {
        return {
            ...styles,
            color: "#212529",
        }
    },
    valueContainer: (styles: any, state: any) => {
        return {
            ...styles,
            color: "#212529",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "4px",
            paddingBottom: "4px",
        }
    },
    menu: (styles: any, state: any) => ({
        ...styles,
        borderRadius: '20px',
        overflow: 'hidden',
    }),
    option: (styles: any, state: any) => ({
        ...styles,
        paddingLeft: '24px',
        paddingRight: '24px'
    }),
}

const AsyncFloatingLabelSelectStyle = {
    ...AsyncSelectStyle,
    container: (styles: any, state: any) => {
        return {
            ...styles,
            '& + label': {
                opacity: state.hasValue || state.selectProps.placeholder ? '0.65' : '1',
                transform: state.hasValue || state.selectProps.placeholder ? 'scale(0.85) translateY(-0.5rem) translateX(0.15rem)' : '',
            },
        }
    },
    valueContainer: (styles: any, state: any) => {
        return {
            ...styles,
            color: "#212529",
            minHeight: 'calc(3.5rem + 2px)',
            lineHeight: 1.25,
            padding: '1rem 20px',
            paddingTop: '1.625rem',
            paddingBottom: '0.625rem',
        }
    },
    menu: (styles: any, state: any) => ({
        ...styles,
        borderRadius: '20px',
        overflow: 'hidden',
        zIndex: 3,
    }),
}

const AsyncFloatingSelectStyle = {
    ...AsyncSelectStyle,
    valueContainer: (styles: any, state: any) => {
        return {
            ...styles,
            color: "#212529",
            minHeight: 'calc(3.5rem + 2px)',
            lineHeight: 1.25,
            padding: '1rem 20px',
        }
    },
    menu: (styles: any, state: any) => ({
        ...styles,
        borderRadius: '20px',
        overflow: 'hidden',
        zIndex: 3,
    }),
}

const AsyncSelectTipoCredor = {
    control: (styles: any, state: any) => {
        return {
            ...styles,
            borderRadius: '20px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        }
    },
    menu: (styles: any, state: any) => ({
        ...styles,
        borderRadius: '20px',
        overflow: 'hidden',
    }),
}

const AsyncHeaderSelectStyle = {
    ...AsyncSelectStyle,
    control: (styles: any, state: any) => {
        return {
            ...styles,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: state.isFocused ? "var(--cor_secundaria) !important" : "rgba(255, 255, 255, 0.55)",
            boxShadow: 'none',
            borderRadius: '20px',
            backgroundColor: state.isFocused ? "white" : "var(--cor_principal)",
            minWidth: 250,
            width: "auto",
            outline: 'none',
            cursor: 'text',
        }
    },
    valueContainer: (styles: any, state: any) => {
        return {
            ...styles,
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "4px",
            paddingBottom: "4px",
            outline: 'none'
        }
    },
    placeholder: (styles: any, state: any) => {
        return {
            ...styles,
            // color: "rgba(255, 255, 255, 0.55)",
            color: state.isFocused ? "rgba(0, 0, 0, 0.55)" : "rgba(255, 255, 255, 0.55)",
        }
    },
    input: (styles: any, state: any) => {
        return {
            ...styles,
            color: "black",
        }
    },
    singleValue: (styles: any, state: any) => {
        return {
            ...styles,
            color: "rgba(255, 255, 255, 0.75)",
        }
    },
    menu: (styles: any, state: any) => ({
        ...styles,
        borderRadius: '20px',
        overflow: 'hidden',
        maxWidth: "calc(100vw - 20px)",
        width: "450px",
        margin: 0,
    }),
    menuList: (styles: any, state: any) => ({
        ...styles,
        maxHeight: 500,
    }),
}

const ProfessionTypeIdOptions = [
    {label: 'Desenvolvedor', value: 8},
    {label: 'QA', value: 9},
    {label: 'Comercial', value: 10},
    {label: 'Marketing', value: 11},
    {label: 'Administrativo', value: 12},
    {label: 'Diretor(a)', value: 13}
];// Pegar do Server

enum RelationshipRequestType {
    FriendInvite = 1,
    CommunityToPersonInvite = 2,
    PersonToCommunityRequest = 3,
    CommunityToCommunityInvite = 4,
}

enum CommunityTypeId {
    Private = 1,
    Public = 2,
    PrivateTopic = 3,
    PublicTopic = 4,
}

enum CommunityUserStatus {
    None = 0,
    Admin = 1,
    Member = 2,
    PendingRequest = 100,
    Invited = 101,
}

enum PersonRelationshipStatus {
    None = 0,
    Following = 1,
    Blocked = 2,
    PendingRequest = 100,
}

enum PostContentType{
    None = -1,
    Text = 0,
    Photo = 1,
    Video = 2,
    Office = 3,
    Pdf = 4,
    Link = 5,
    Attach = 6,
    Sharing = 7,
    Checkin = 8,
    YouTube = 9,
    Vimeo = 10,
    FaceBook = 11,
    LinkedIn = 12,
    Twitter = 13,
    Html = 14
}

enum AlertStatus{
    Read = 0,
    Unread = 1
}

enum AlertType{
    Perception = 1,
    Mention = 2,
    Comment = 3,
    Post = 4,
    NewRelationship = 5,
    NewRelationshipRequest = 6,
    AcceptedSentRelationshipRequest = 7
}

enum JobLocation{
    Remote = 0,
    OnSite = 1,
    Hybrid = 2,
    Other = 3
}

const JobLocationOptions = [
    {label: "Remoto", value: Number(JobLocation.Remote)},
    {label: "Local", value: Number(JobLocation.OnSite)},
    {label: "Híbrido", value: Number(JobLocation.Hybrid)},
    {label: "Outro", value: Number(JobLocation.Other)},
];

enum JobModality{
    FullTime = 0,
    PartTime = 1,
    Freelance = 2,
    Internship = 3,
    Temporary = 4,
    Other = 5
}

const JobModalityOptions = [
    {label: "Integral", value: Number(JobModality.FullTime)},
    {label: "Meio período", value: Number(JobModality.PartTime)},
    {label: "Freelance", value: Number(JobModality.Freelance)},
    {label: "Estágio", value: Number(JobModality.Internship)},
    {label: "Temporário", value: Number(JobModality.Temporary)},
    {label: "Outro", value: Number(JobModality.Other)},
];

enum EntityType{
    Person = 1,
    Community = 2,
    Post = 3,
    Hashtag = 4,
    Event = 5,
    Job = 6,
    Place = 7,
}

enum AnalyticsEvents{
    ACCESS_PAGE = "access_page",
    ACTION_SUCESS = "action_sucess",
}
enum AnalyticsEventsParams{
    PAGE = "page",
    ACTION = "action",
}


const CPFMask = "999.999.999-999";
const CNPJMask = "99.999.999/9999-99";

const reportTypeIdOptions = [
    {label: "É spam", value: 1},
    {label: "Nudez ou atividade sexual", value: 2},
    {label: "Símbolos ou discurso de ódio", value: 3},
    {label: "Violência ou organizações perigosas", value: 4},
    {label: "Bullying ou assédio", value: 5},
    {label: "Venda de produtos regulamentados ou ilícitos", value: 6},
    {label: "Violação de propriedade intelectual", value: 7},
    {label: "Suicídio ou automutilação", value: 8},
    {label: "Distúrbios alimentares", value: 9},
    {label: "Golpe ou fraude", value: 10},
    {label: "Informação falsa", value: 11},
    {label: "Outro", value: 12},
];

export type { Unpacked };

export { 
    ProfessionTypeIdOptions,
    AsyncSelectStyle,
    AsyncFloatingLabelSelectStyle,
    AsyncFloatingSelectStyle,
    AsyncSelectTipoCredor,
    AsyncHeaderSelectStyle,
    RelationshipRequestType,
    CommunityTypeId,
    CommunityUserStatus,
    PersonRelationshipStatus,
    PostContentType,
    AlertStatus,
    AlertType,
    EntityType,
    JobLocation,
    JobLocationOptions,
    JobModality,
    JobModalityOptions,
    AnalyticsEvents,
    AnalyticsEventsParams,
    reportTypeIdOptions,
    CPFMask,
    CNPJMask
};